<template>
  <div class="h-full">
    <v-card outlined @click="openWidget()" class="h-full">
      <v-card-title class="relative">
        Declined Assignments
        <span v-if="loading" class="loader"><Spinner /></span>
        <span v-else class="number green">{{ declinedAssignments.length }}</span>
      </v-card-title>

      <v-card-text>
        Your location(s) have {{ declinedAssignments.length }} declined
        {{ pluralize('assignment', declinedAssignments.length) }} that have not yet been reassigned
      </v-card-text>
    </v-card>

    <modal-assignment-list
      ref="declinedAssignments"
      name="Upcoming Assignments"
      :assignments="declinedAssignments"
    ></modal-assignment-list>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { pluralize, todayString } from '@/util';
import ModalAssignmentList from './ModalAssignmentList.vue';
import Spinner from '@/components/shared/Spinner.vue';
import { ASSIGNMENT_STATUS } from '@/shared/common';

export default {
  components: { ModalAssignmentList, Spinner },
  props: {},
  mounted() {
    if (this.dashboardPanel == 'declinedAssignments') this.openWidget();
  },
  computed: {
    ...mapGetters('user', ['me']),
    ...mapGetters('assignment', { assignments: 'assignments', loading: 'isLoading' }),
    ...mapGetters('tripRequest', ['tripRequestsById']),
    ...mapGetters('app', ['dashboardPanel']),
    declinedAssignments() {
      const filtered = this.assignments.filter(
        (e) =>
          (e.status == ASSIGNMENT_STATUS.SELF_DECLINED || e.status == ASSIGNMENT_STATUS.ADMIN_DECLINED) &&
          this.tripRequestsById[e.tripRequestId]?.pendingDrivers
      );

      const byTripId = {};
      filtered.forEach((e) => {
        if (!byTripId[e.tripRequestId]) byTripId[e.tripRequestId] = [];
        byTripId[e.tripRequestId].push(e);
      });

      const assignments = [];
      for (let tripId of Object.keys(byTripId)) {
        const sameTrip = byTripId[tripId];
        if (sameTrip.length > 1) {
          const trip = this.tripRequestsById[tripId];
          const openings =
            (trip.splitTrip ? 2 : 1) * trip.numVehicles -
            trip.assignments.filter((e) => e.status >= 0 && (e.driverId || e.driver)).length;
          const addToList = sameTrip.sort((a, b) => b.reviewedAt - a.reviewedAt).slice(0, openings);
          addToList.forEach((e) => assignments.push(e));
        } else assignments.push(byTripId[tripId][0]);
      }

      return assignments.sort((a, b) => a.leaveDate.localeCompare(b.leaveDate, undefined, { numeric: true }));
    },
  },
  methods: {
    ...mapMutations('app', ['setDashboardPanel']),
    pluralize,
    openWidget() {
      this.setDashboardPanel('declinedAssignments');
      this.$refs.declinedAssignments.dialog = true;
    },
  },
};
</script>
