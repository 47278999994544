var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "h-full" },
    [
      _c(
        "v-card",
        {
          staticClass: "h-full",
          attrs: { outlined: "" },
          on: {
            click: function ($event) {
              return _vm.openWidget()
            },
          },
        },
        [
          _c("v-card-title", { staticClass: "relative" }, [
            _vm._v(" Declined Assignments "),
            _vm.loading
              ? _c("span", { staticClass: "loader" }, [_c("Spinner")], 1)
              : _c("span", { staticClass: "number green" }, [
                  _vm._v(_vm._s(_vm.declinedAssignments.length)),
                ]),
          ]),
          _c("v-card-text", [
            _vm._v(
              " Your location(s) have " +
                _vm._s(_vm.declinedAssignments.length) +
                " declined " +
                _vm._s(
                  _vm.pluralize("assignment", _vm.declinedAssignments.length)
                ) +
                " that have not yet been reassigned "
            ),
          ]),
        ],
        1
      ),
      _c("modal-assignment-list", {
        ref: "declinedAssignments",
        attrs: {
          name: "Upcoming Assignments",
          assignments: _vm.declinedAssignments,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }