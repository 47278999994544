var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-toolbar-items",
    [
      _c(
        "v-menu",
        {
          attrs: { "offset-y": "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on, attrs }) {
                return [
                  _vm.operations.length
                    ? _c(
                        "v-btn",
                        _vm._g(
                          _vm._b(
                            {
                              staticClass: "text-capitalize",
                              attrs: { color: "#000", text: "" },
                            },
                            "v-btn",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [
                          _vm._v(" Operations "),
                          _c("v-icon", [_vm._v("mdi-menu-down")]),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "v-list",
            [
              _vm._l(_vm.operations, function (operation, index) {
                return [
                  operation.show
                    ? _c(
                        "v-list-item",
                        { key: index, on: { click: operation.onClick } },
                        [
                          _c("v-list-item-title", [
                            _vm._v(_vm._s(operation.name)),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }