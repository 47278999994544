<template>
  <v-container fluid class="px-8 dashboard">
    <v-row class="mb-4" v-if="loading">
      <v-col cols="12">
        <v-progress-linear color="primary" absolute indeterminate></v-progress-linear>
      </v-col>
    </v-row>
    <v-row class="mb-4 mt-2">
      <v-col cols="12" class="d-flex py-0">
        <v-btn class="mr-2" data-cy="new-trip-btn" dark color="primary" @click="createRequest()">
          <v-icon left>mdi-plus</v-icon>New Trip
        </v-btn>
        <AssignmentListMenu />
      </v-col>
    </v-row>

    <v-row class="align-baseline">
      <v-col cols="12" md="3" v-if="showNeedAttention">
        <NeedAttention />
      </v-col>
      <v-col cols="12" md="3" v-if="showReadyToAssign">
        <ReadyToAssign />
      </v-col>
      <v-col cols="12" md="3" v-if="showAwaitingApproval">
        <AwaitingApproval />
      </v-col>
      <v-col cols="12" md="3" v-if="showMyTripRequests">
        <MyTripRequests />
      </v-col>
      <v-col cols="12" md="3" v-if="showChangesRequested">
        <ChangesRequested />
      </v-col>
      <!-- <v-col cols="12" md="3" v-if="showApproved">
        <Approved />
      </v-col> -->
      <v-col cols="12" md="3" v-if="showOvernightOOS">
        <OvernightOOS />
      </v-col>
      <v-col cols="12" md="3" v-if="showUpcomingTrips">
        <UpcomingTrips />
      </v-col>
      <v-col cols="12" md="3" v-if="showUpcomingAssignments">
        <UpcomingAssignments />
      </v-col>
      <v-col cols="12" md="3" v-if="showRecentlyCompleted">
        <RecentlyCompleted />
      </v-col>
      <v-col cols="12" md="3" v-if="showThirdPartyPayment">
        <ThirdPartyPayment />
      </v-col>
      <v-col cols="12" md="3" v-if="showHealthConcerns">
        <HealthConcerns />
      </v-col>
      <v-col cols="12" md="3" v-if="showApprovedPayments">
        <ApprovedPayments />
      </v-col>
      <v-col cols="12" md="6" v-if="showScheduledVehicles">
        <ScheduledVehicles />
      </v-col>
      <v-col cols="12" md="3" v-if="showPendingAssignments">
        <PendingAssignments />
      </v-col>
      <v-col cols="12" md="3" v-if="showDeclinedAssignments">
        <DeclinedAssignments />
      </v-col>
      <v-col cols="12" md="3" v-if="showNutrition">
        <Nutrition />
      </v-col>
      <v-col cols="12" md="3" v-if="showPaymentSent">
        <PaymentSent />
      </v-col>

      <!-- removing for now but may be added back later -->
      <!-- <v-col cols="12" md="6" v-show="me.yellowfinId">
        <div id="dashDiv"></div>
      </v-col> -->
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { uniq } from 'lodash';
import Approved from './Widgets/Approved.Widget.vue';
import ApprovedPayments from './Widgets/ApprovedPayments.Widget.vue';
import AssignmentListMenu from './Menu.vue';
import AwaitingApproval from './Widgets/AwaitingApproval.Widget.vue';
import ChangesRequested from './Widgets/ChangesRequested.Widget.vue';
import DeclinedAssignments from './Widgets/DeclinedAssignments.Widget.vue';
import HealthConcerns from './Widgets/HealthConcerns.Widget.vue';
import MyTripRequests from './Widgets/MyTripRequests.Widget.vue';
import NeedAttention from './Widgets/NeedAttention.Widget.vue';
import Nutrition from './Widgets/Nutrition.Widget.vue';
import OvernightOOS from './Widgets/OvernightOOS.Widget.vue';
import PaymentSent from './Widgets/PaymentSent.Widget.vue';
import PendingAssignments from './Widgets/PendingAssignments.Widget.vue';
import ReadyToAssign from './Widgets/ReadyToAssign.Widget.vue';
import RecentlyCompleted from './Widgets/RecentlyCompleted.Widget.vue';
import ScheduledVehicles from './Widgets/ScheduledVehicles.Widget.vue';
import ThirdPartyPayment from './Widgets/ThirdPartyPayment.Widget.vue';
import UpcomingAssignments from './Widgets/UpcomingAssignments.Widget.vue';
import UpcomingTrips from './Widgets/UpcomingTrips.Widget.vue';

export default {
  components: {
    // Approved,
    ApprovedPayments,
    AssignmentListMenu,
    AwaitingApproval,
    ChangesRequested,
    DeclinedAssignments,
    HealthConcerns,
    MyTripRequests,
    NeedAttention,
    Nutrition,
    OvernightOOS,
    PaymentSent,
    PendingAssignments,
    ReadyToAssign,
    RecentlyCompleted,
    ScheduledVehicles,
    ThirdPartyPayment,
    UpcomingAssignments,
    UpcomingTrips,
  },
  data() {
    return {
      yellowfin: window.yellowfin,
      loading: false,
    };
  },
  async mounted() {
    await this.setStore();
  },
  computed: {
    ...mapGetters('user', ['me']),
    ...mapGetters('config', ['permissions', 'driverConfig', 'schoolFinanceMileageEditable']),
    ...mapGetters('app', ['currentSemester']),
    ...mapGetters('location', ['locationsById']),
    ...mapGetters('tripRequest', ['tripRequests']),
    ...mapGetters('assignment', ['assignments']),
    showNeedAttention() {
      return (
        this.me.is.superAdmin ||
        this.me.is.transportationAdmin ||
        this.me.is.limitedAdmin ||
        this.me.is.vehicleOwner ||
        this.me.is.specialNeedsVehicleOwner
      );
    },
    showReadyToAssign() {
      return (
        this.me.is.superAdmin ||
        this.me.is.transportationAdmin ||
        this.me.is.limitedAdmin ||
        this.me.is.vehicleOwner ||
        this.me.is.specialNeedsVehicleOwner
      );
    },
    showAwaitingApproval() {
      return this.me.is.superAdmin || this.me.is.transportationAdmin || this.me.is.limitedAdmin || this.me.is.approver;
    },
    showApprovedPayments() {
      return (
        this.me.is.superAdmin ||
        this.me.is.transportationAdmin ||
        this.me.is.limitedAdmin ||
        this.me.is.finance ||
        this.me.is.schoolFinance ||
        this.me.is.fundingManager
      );
    },
    showPaymentSent() {
      return this.me.is.superAdmin || this.me.is.transportationAdmin || this.me.is.finance || this.me.is.limitedAdmin;
    },
    showPendingAssignments() {
      return (
        (this.driverConfig.driverAccept || this.driverConfig.driverDecline) &&
        (this.me.is.superAdmin ||
          this.me.is.transportationAdmin ||
          this.me.is.limitedAdmin ||
          this.me.is.vehicleOwner ||
          this.me.is.specialNeedsVehicleOwner)
      );
    },
    showDeclinedAssignments() {
      return (
        (this.driverConfig.driverAccept || this.driverConfig.driverDecline) &&
        (this.me.is.superAdmin ||
          this.me.is.transportationAdmin ||
          this.me.is.limitedAdmin ||
          this.me.is.vehicleOwner ||
          this.me.is.specialNeedsVehicleOwner)
      );
    },
    showScheduledVehicles() {
      return (
        this.me.is.superAdmin ||
        this.me.is.transportationAdmin ||
        this.me.is.limitedAdmin ||
        this.me.is.vehicleOwner ||
        this.me.is.specialNeedsVehicleOwner ||
        this.me.is.siteAdmin ||
        this.me.is.siteAdminReadOnly ||
        this.me.is.schoolFinance ||
        this.me.is.fundingManager
      );
    },
    showMyTripRequests() {
      return true;
    },
    showChangesRequested() {
      return true;
    },
    showApproved() {
      return this.me.is.superAdmin || this.me.is.transportationAdmin || this.me.is.limitedAdmin || this.me.is.approver;
    },
    showOvernightOOS() {
      return (
        this.me.is.superAdmin ||
        this.me.is.transportationAdmin ||
        this.me.is.limitedAdmin ||
        this.me.is.overnightOOSOnly
      );
    },
    showUpcomingTrips() {
      return (
        this.me.is.superAdmin ||
        this.me.is.transportationAdmin ||
        this.me.is.limitedAdmin ||
        this.me.is.siteAdmin ||
        this.me.is.siteAdminReadOnly ||
        this.me.is.schoolFinance
      );
    },
    showUpcomingAssignments() {
      return (
        this.me.is.superAdmin ||
        this.me.is.transportationAdmin ||
        this.me.is.limitedAdmin ||
        this.me.is.vehicleOwner ||
        this.me.is.specialNeedsVehicleOwner ||
        this.me.is.siteAdmin ||
        this.me.is.siteAdminReadOnly ||
        this.me.is.schoolFinance
      );
    },
    showRecentlyCompleted() {
      return (
        this.me.is.superAdmin ||
        this.me.is.transportationAdmin ||
        this.me.is.limitedAdmin ||
        this.me.is.siteAdmin ||
        this.schoolFinanceMileageEditable ||
        (this.me.is.requester && this.permissions.requester && this.permissions.requester.enterMileage)
      );
    },
    showThirdPartyPayment() {
      return (
        this.me.is.superAdmin ||
        this.me.is.transportationAdmin ||
        this.me.is.limitedAdmin ||
        this.me.is.finance ||
        this.me.is.schoolFinance ||
        this.me.is.fundingManager
      );
    },
    showHealthConcerns() {
      return this.me.is.superAdmin || this.me.is.transportationAdmin || this.me.is.limitedAdmin || this.me.is.nurse;
    },
    showNutrition() {
      return (
        this.me.is.superAdmin ||
        this.me.is.transportationAdmin ||
        this.me.is.limitedAdmin ||
        this.me.is.nutritionist ||
        this.me.is.childNutritionistDirector
      );
    },
    locations() {
      if (
        this.me.is.superAdmin ||
        this.me.is.transportationAdmin ||
        this.me.is.limitedAdmin ||
        this.me.is.finance ||
        this.me.is.readOnly ||
        this.me.is.childNutritionistDirector
      ) {
        return '';
      }

      const locationIds = uniq(this.me.roles.map((e) => e.locationIds).flat()).filter((e) => e);
      return locationIds.map((e) => this.locationsById[e].name);
    },
  },
  methods: {
    ...mapActions('tripRequest', ['getTripRequests']),
    ...mapActions('assignment', ['getAssignments']),
    createRequest() {
      this.$router.push('/trip-request/0?dashboard=true');
    },
    async setStore() {
      try {
        await this.getTripRequests();
        await this.getAssignments({ tripRequestIds: this.tripRequests.map((e) => e.id) });
      } catch (error) {
        console.log('Failed Store Fetch');
      }
    },
  },
};
</script>

<style lang="scss">
.dashboard {
  .h-full {
    height: 100%;
    min-height: 150px;
  }
  .loader {
    margin-left: auto;
    width: 32px;
    height: 32px;
    position: relative;
    bottom: 15px;
  }
  .number {
    color: white;
    margin-left: auto;
    padding: 3px;
    min-width: 40px;
    border-radius: 50%;
    text-align: center;
  }
}
</style>
