var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "h-full" },
    [
      _c(
        "v-card",
        {
          staticClass: "h-full",
          attrs: { outlined: "" },
          on: {
            click: function ($event) {
              return _vm.openWidget()
            },
          },
        },
        [
          _c("v-card-title", { staticClass: "relative" }, [
            _vm._v(" Third Party Payment "),
            _vm.loading
              ? _c("span", { staticClass: "loader" }, [_c("Spinner")], 1)
              : _c("span", { staticClass: "number", class: _vm.numberClass }, [
                  _vm._v(_vm._s(_vm.thirdPartyPayment.length)),
                ]),
          ]),
          _c("v-card-text", [
            _vm._v(
              " There " +
                _vm._s(_vm.thirdPartyPayment.length != 1 ? "are" : "is") +
                " " +
                _vm._s(_vm.thirdPartyPayment.length) +
                " " +
                _vm._s(_vm.thirdPartyPayment.length != 1 ? "trips" : "trip") +
                " that " +
                _vm._s(_vm.thirdPartyPayment.length != 1 ? "have" : "has") +
                " indicated that funds are payable to a third party "
            ),
          ]),
        ],
        1
      ),
      _c("modal-trip-list", {
        ref: "thirdPartyPayment",
        attrs: { name: "Third Party Payment", trips: _vm.thirdPartyPayment },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }