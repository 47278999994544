var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "h-full" },
    [
      _c(
        "v-card",
        { staticClass: "h-full", attrs: { outlined: "" } },
        [
          _c("v-card-title", [
            _c("div", { staticClass: "d-flex justify-space-between w-100" }, [
              _c("span", [_vm._v("Today's Scheduled Vehicles")]),
              _c(
                "div",
                { staticClass: "d-flex align-center" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "ml-auto mr-4",
                      attrs: { color: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.openWidget()
                        },
                      },
                    },
                    [_vm._v("View Assignments")]
                  ),
                  _c("span", { staticClass: "s-number green" }, [
                    _vm._v(_vm._s(_vm.scheduledAssignments.length)),
                  ]),
                ],
                1
              ),
            ]),
          ]),
          _c(
            "v-card-text",
            [
              _c("v-simple-table", {
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function () {
                      return [
                        _c("thead", [
                          _c("tr", [
                            _c("th", { staticClass: "text-left" }, [
                              _vm._v("Trip #"),
                            ]),
                            _c("th", { staticClass: "text-left" }, [
                              _vm._v("Driver"),
                            ]),
                            _c("th", { staticClass: "text-left" }, [
                              _vm._v("Vehicle"),
                            ]),
                            _c("th", { staticClass: "text-left" }, [
                              _vm._v("Vehicle Location"),
                            ]),
                            _c("th", { staticClass: "text-left" }, [
                              _vm._v("Vehicle Pickup"),
                            ]),
                            _c("th", { staticClass: "text-left" }, [
                              _vm._v("Vehicle Return"),
                            ]),
                          ]),
                        ]),
                        _c(
                          "tbody",
                          _vm._l(_vm.scheduledAssignments, function (a) {
                            return _c(
                              "tr",
                              {
                                key: a.id,
                                on: {
                                  click: function ($event) {
                                    return _vm.$router.push(
                                      `/trip-request/${a.tripRequestId}?assignment=true&dashboard=true`
                                    )
                                  },
                                },
                              },
                              [
                                _c("td", [_vm._v(_vm._s(a.tripRequestId))]),
                                _c("td", [_vm._v(_vm._s(_vm.getDriver(a)))]),
                                _c("td", [_vm._v(_vm._s(_vm.getVehicle(a)))]),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.getVehicleLocation(a.vehicleId) || "-"
                                    )
                                  ),
                                ]),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(_vm.getVehiclePickupDateTime(a))
                                  ),
                                ]),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(_vm.getVehicleReturnDateTime(a))
                                  ),
                                ]),
                              ]
                            )
                          }),
                          0
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("modal-assignment-list", {
        ref: "schedule",
        attrs: {
          name: "Today's Assignments",
          assignments: _vm.scheduledAssignments,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }