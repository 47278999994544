var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "h-full" },
    [
      _c(
        "v-card",
        {
          staticClass: "h-full",
          attrs: { outlined: "" },
          on: {
            click: function ($event) {
              return _vm.openWidget()
            },
          },
        },
        [
          _c("v-card-title", [
            _vm._v(" Invoices Approved for Payment "),
            _c("span", { staticClass: "number green" }, [
              _vm._v(_vm._s(_vm.invoiceCount)),
            ]),
          ]),
          _c("v-card-text", [
            _vm._v(
              "You have " +
                _vm._s(_vm.invoiceCount) +
                " invoice(s) approved for payment"
            ),
          ]),
        ],
        1
      ),
      _c("modal-invoice-list", {
        ref: "approved",
        attrs: { title: "Approved Payments Invoices", options: _vm.options },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }