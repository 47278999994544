var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "h-full" },
    [
      _c(
        "v-card",
        {
          staticClass: "h-full",
          attrs: { outlined: "" },
          on: {
            click: function ($event) {
              return _vm.openWidget()
            },
          },
        },
        [
          _c("v-card-title", { staticClass: "relative" }, [
            _vm._v(" Need Attention "),
            _vm.loading
              ? _c("span", { staticClass: "loader" }, [_c("Spinner")], 1)
              : _c("span", { staticClass: "number", class: _vm.numberClass }, [
                  _vm._v(_vm._s(_vm.needAttention.length)),
                ]),
          ]),
          _c("v-card-text", [
            _c("p", [
              _vm._v(
                " You have " +
                  _vm._s(_vm.needAttention.length) +
                  " " +
                  _vm._s(_vm.needAttention.length != 1 ? "trips" : "trip") +
                  " upcoming in the next 5 days that aren't approved and/or need assignments. "
              ),
            ]),
            _vm.tbdTrips.length
              ? _c("p", [
                  _vm._v(
                    " The leave/return date/time for " +
                      _vm._s(_vm.tbdTrips.length) +
                      " " +
                      _vm._s(_vm.tbdTrips.length != 1 ? "trips" : "trip") +
                      " in the next 5 days is still marked TBD. "
                  ),
                ])
              : _vm._e(),
          ]),
        ],
        1
      ),
      _c("modal-trip-list", {
        ref: "attention",
        attrs: { name: "Need Attention", trips: _vm.needAttention },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }