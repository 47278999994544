var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        fullscreen: "",
        "hide-overlay": "",
        transition: "dialog-bottom-transition",
      },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "blue-grey lighten-5" },
        [
          _c(
            "v-toolbar",
            { staticClass: "mb-4", attrs: { dark: "", color: "primary" } },
            [
              _c(
                "v-btn",
                { attrs: { icon: "", dark: "" }, on: { click: _vm.close } },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
              _c("v-toolbar-title", [_vm._v(_vm._s(_vm.name))]),
            ],
            1
          ),
          _c(
            "v-row",
            {
              staticClass: "px-8 align-start",
              attrs: { dense: "", "no-gutters": "" },
            },
            [
              _c(
                "v-chip",
                { staticClass: "mx-2 button-chip", attrs: { label: "" } },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.sortableAssignments.length) +
                      " Assignment" +
                      _vm._s(_vm.sortableAssignments.length != 1 ? "s" : "") +
                      " "
                  ),
                ]
              ),
              _c(
                "v-menu",
                {
                  attrs: { "offset-y": "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on }) {
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              {
                                staticClass: "text-capitalize",
                                attrs: { text: "", color: "#000" },
                              },
                              on
                            ),
                            [
                              _vm._v(" Sort "),
                              _c("v-icon", [_vm._v("mdi-menu-down")]),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c(
                    "v-list",
                    _vm._l(_vm.sortOptions, function (s, i) {
                      return _c(
                        "v-list-item",
                        {
                          key: i,
                          on: {
                            click: function ($event) {
                              return _vm.setCurrentSort(i)
                            },
                          },
                        },
                        [
                          _c(
                            "v-list-item-title",
                            [
                              _vm._v(" " + _vm._s(s.label) + " "),
                              !!s.order
                                ? _c("v-icon", [
                                    _vm._v(
                                      _vm._s(
                                        s.order == "desc"
                                          ? "mdi-chevron-down"
                                          : "mdi-chevron-up"
                                      )
                                    ),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c("v-spacer"),
              _vm.driverConfig.driverDecline || _vm.driverConfig.driverAccept
                ? _c(
                    "v-tooltip",
                    {
                      staticClass: "m-0 mt-4",
                      attrs: { bottom: "", contained: "", color: "#fff" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on, attrs }) {
                              return [
                                _c(
                                  "small",
                                  [
                                    _c(
                                      "v-chip",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass: "button-chip",
                                            attrs: {
                                              rounded: "",
                                              outlined: "",
                                            },
                                          },
                                          "v-chip",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "mr-2",
                                            attrs: { color: "blue" },
                                          },
                                          [_vm._v("mdi-information")]
                                        ),
                                        _vm._v("Color Codes "),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3060922887
                      ),
                    },
                    [
                      _c("div", { staticClass: "mx-2" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "font-weight-bold green--text text--darken-1",
                          },
                          [_vm._v("Driver Accepted")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "font-weight-bold blue--text text--darken-2",
                          },
                          [_vm._v("Driver Assigned (Pending Action)")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "font-weight-bold orange--text text--darken-3",
                          },
                          [_vm._v("No Driver Assigned")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "font-weight-bold red--text text-accent-2",
                          },
                          [_vm._v("Driver Declined")]
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
              _c("v-switch", {
                staticClass: "ma-0 pa-0 mx-4 mt-2",
                attrs: { label: "Expand All", "hide-details": "" },
                model: {
                  value: _vm.allExpanded,
                  callback: function ($$v) {
                    _vm.allExpanded = $$v
                  },
                  expression: "allExpanded",
                },
              }),
            ],
            1
          ),
          _c(
            "v-row",
            {
              staticClass: "mt-4 px-8 pb-16",
              attrs: { justify: "center", dense: "", "no-gutters": "" },
            },
            _vm._l(_vm.sortableAssignments, function (a, i) {
              return _c(
                "v-col",
                {
                  key: a.id,
                  staticClass: "mt-2",
                  attrs: { cols: "12", md: "12" },
                },
                [
                  _c("assignment", {
                    attrs: {
                      assignmentProp: a,
                      allExpanded: _vm.allExpanded,
                      groupNext:
                        i + 1 < _vm.sortableAssignments.length &&
                        _vm.sortableAssignments[i + 1].tripRequestId ==
                          a.tripRequestId,
                      returnToDashboard: "",
                    },
                  }),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }