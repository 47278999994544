var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "h-full" },
    [
      _c(
        "v-card",
        {
          staticClass: "h-full",
          attrs: { outlined: "" },
          on: {
            click: function ($event) {
              return _vm.openWidget()
            },
          },
        },
        [
          _c("v-card-title", { staticClass: "relative" }, [
            _vm._v(" Upcoming Trips "),
            _vm.loading
              ? _c("span", { staticClass: "loader" }, [_c("Spinner")], 1)
              : _c("span", { staticClass: "number green" }, [
                  _vm._v(_vm._s(_vm.upcomingTrips.length)),
                ]),
          ]),
          _c("v-card-text", [
            _vm._v(
              " Your location(s) have " +
                _vm._s(_vm.upcomingTrips.length) +
                " upcoming " +
                _vm._s(_vm.upcomingTrips.length != 1 ? "trips" : "trip") +
                " "
            ),
          ]),
        ],
        1
      ),
      _c("modal-trip-list", {
        ref: "upcomingTrips",
        attrs: { name: "Upcoming Trips", trips: _vm.upcomingTrips },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }