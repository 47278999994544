var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "h-full" },
    [
      _c(
        "v-card",
        {
          staticClass: "h-full",
          attrs: { outlined: "" },
          on: {
            click: function ($event) {
              return _vm.openWidget()
            },
          },
        },
        [
          _c("v-card-title", { staticClass: "relative" }, [
            _vm._v(" Ready To Assign "),
            _vm.loading
              ? _c("span", { staticClass: "loader" }, [_c("Spinner")], 1)
              : _c("span", { staticClass: "number", class: _vm.numberClass }, [
                  _vm._v(_vm._s(_vm.readyToAssign.length)),
                ]),
          ]),
          _c("v-card-text", [
            _vm._v(
              " You have " +
                _vm._s(_vm.readyToAssign.length) +
                " " +
                _vm._s(_vm.pluralize("trip", _vm.readyToAssign.length)) +
                " that are approved & ready to be assigned "
            ),
          ]),
        ],
        1
      ),
      _c("modal-trip-list", {
        ref: "assign",
        attrs: {
          name: "Ready to Assign",
          trips: _vm.readyToAssign,
          tab: "assignment",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }