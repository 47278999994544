var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "h-full" },
    [
      _c(
        "v-card",
        { staticClass: "h-full", attrs: { outlined: "" } },
        [
          _c("v-card-title", { staticClass: "relative" }, [
            _vm._v(" Trips w/ Students Away for Lunch "),
            _vm.loading
              ? _c("span", { staticClass: "loader" }, [_c("Spinner")], 1)
              : _c("span", { staticClass: "number", class: _vm.numberClass }, [
                  _vm._v(_vm._s(_vm.awayForLunch.length)),
                ]),
          ]),
          _c("v-card-text", [
            _c(
              "p",
              [
                _vm._v(
                  " There " +
                    _vm._s(_vm.awayForLunch.length != 1 ? "are" : "is") +
                    " " +
                    _vm._s(_vm.awayForLunch.length) +
                    " upcoming " +
                    _vm._s(_vm.awayForLunch.length != 1 ? "trips" : "trip") +
                    " where the submitter has indicated that students will be away "
                ),
                _c(
                  "v-btn",
                  {
                    attrs: { outlined: "", small: "", text: "" },
                    on: {
                      click: function ($event) {
                        return _vm.openWidget("awayForLunch")
                      },
                    },
                  },
                  [_vm._v("View Trips")]
                ),
              ],
              1
            ),
            _c(
              "p",
              [
                _vm._v(
                  " There " +
                    _vm._s(_vm.needLunch.length != 1 ? "are" : "is") +
                    " " +
                    _vm._s(_vm.needLunch.length) +
                    " upcoming " +
                    _vm._s(_vm.needLunch.length != 1 ? "trips" : "trip") +
                    " where the submitter has indicated that students will need packed lunches "
                ),
                _c(
                  "v-btn",
                  {
                    attrs: { outlined: "", small: "", text: "" },
                    on: {
                      click: function ($event) {
                        return _vm.openWidget("needLunch")
                      },
                    },
                  },
                  [_vm._v("View Trips")]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c("modal-trip-list", {
        ref: "lunch",
        attrs: { name: "Trips w/ Students Away for Lunch", trips: _vm.trips },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }