var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "h-full" },
    [
      _c(
        "v-card",
        {
          staticClass: "h-full",
          attrs: { outlined: "" },
          on: {
            click: function ($event) {
              return _vm.openWidget()
            },
          },
        },
        [
          _c("v-card-title", { staticClass: "relative" }, [
            _vm._v(" Recently Completed "),
            _vm.loading
              ? _c("span", { staticClass: "loader" }, [_c("Spinner")], 1)
              : _c("span", { staticClass: "number green" }, [
                  _vm._v(_vm._s(_vm.recentlyCompleted.length)),
                ]),
          ]),
          _c("v-card-text", [
            _vm._v(
              " There " +
                _vm._s(_vm.recentlyCompleted.length != 1 ? "are" : "is") +
                " " +
                _vm._s(_vm.recentlyCompleted.length) +
                " " +
                _vm._s(
                  _vm.recentlyCompleted.length != 1
                    ? "assignments"
                    : "assignment"
                ) +
                " from recently completed trips that need times and/or mileage entered "
            ),
          ]),
        ],
        1
      ),
      _c("modal-assignment-list", {
        ref: "recentlyCompleted",
        attrs: {
          name: "Recently Completed",
          assignments: _vm.recentlyCompleted,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }