<template>
  <div class="h-full">
    <v-card outlined @click="openWidget()" class="h-full">
      <v-card-title class="relative">
        Ready To Assign
        <span v-if="loading" class="loader"><Spinner /></span>
        <span v-else class="number" :class="numberClass">{{ readyToAssign.length }}</span>
      </v-card-title>
      <v-card-text>
        You have {{ readyToAssign.length }} {{ pluralize('trip', readyToAssign.length) }} that are approved & ready to
        be assigned
      </v-card-text>
    </v-card>

    <modal-trip-list ref="assign" name="Ready to Assign" :trips="readyToAssign" tab="assignment"></modal-trip-list>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { todayString, pluralize } from '@/util';
import ModalTripList from './ModalTripList.vue';
import Spinner from '@/components/shared/Spinner.vue';
import { TRIP_STATUS } from '@/shared/common';

export default {
  components: { ModalTripList, Spinner },
  props: {},
  mounted() {
    if (this.dashboardPanel == 'assign') this.openWidget();
  },
  computed: {
    ...mapGetters('user', ['me']),
    ...mapGetters('tripRequest', { tripRequests: 'tripRequests', loading: 'isLoading' }),
    ...mapGetters('app', ['dashboardPanel']),
    numberClass() {
      return this.readyToAssign.length === 0 ? 'green' : 'red';
    },
    readyToAssign() {
      return this.tripRequests
        .filter(
          (e) =>
            this.isValidTrip(e) &&
            e.approval &&
            e.permissions.canAssign &&
            (e.pendingDrivers || e.pendingVehicles) &&
            new Date(e.leaveDate) >= new Date(todayString()) &&
            (e.approval.approved ||
              (!e.approval.approved && !e.approval.requiredApprovalLevels?.some((item) => item?.canAssign == 1)) ||
              (!e.approval.approved && this.hasNoVehicleAssignmentApprovals(e.approval.requiredApprovalLevels)))
        )
        .sort((a, b) => a.leaveDate.localeCompare(b.leaveDate, undefined, { numeric: true }));
    },
  },
  methods: {
    ...mapMutations('app', ['setDashboardPanel']),
    pluralize,
    openWidget() {
      this.setDashboardPanel('assign');
      this.$refs.assign.dialog = true;
    },
    hasNoVehicleAssignmentApprovals(approvalLevels) {
      if (!Array.isArray(approvalLevels) || !approvalLevels.length) return true;

      const pendingLevels = approvalLevels.filter((level) => level.status === 'pending');
      if (!pendingLevels.length) return true;
      return pendingLevels.every((level) => level.canAssign === false);
    },
    isValidTrip(trip) {
      return trip.status != TRIP_STATUS.DENIED && trip.status != TRIP_STATUS.CANCELLED;
    },
  },
};
</script>
